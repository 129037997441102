<template>
	<v-card
		:class="{ 'editor-mobile': isMobile, editor: !isMobile, scroll: true }"
		color="background"
		flat
	>
		<v-toolbar>
			<v-card-title>
				<v-btn
					v-if="isMedium"
					icon
					class="mr-2"
					@click="showDrawer = !showDrawer"
				>
					<v-icon>mdi-menu</v-icon>
				</v-btn>
				<span
					>Customize your <b>{{ command.title }}</b> command!</span
				>
			</v-card-title>
			<template v-slot:extension>
				<v-tabs v-model="tab" align-with-title>
					<v-tabs-slider color="yellow"></v-tabs-slider>
					<v-tab
						v-for="item in tabs"
						:key="item.id"
						color="white"
						@click="selectTab(item)"
					>
						<span class="tab-text">{{ item.name }}</span>
					</v-tab>
				</v-tabs>
			</template>
		</v-toolbar>
		<v-card-text>
			<v-form ref="form">
				<v-btn color="primary" class="help-button mt-4" text @click="showModal">
					<v-icon class="mr-2" size="18">mdi-help-circle-outline</v-icon>
					Variables
				</v-btn>
				<component class="form" v-bind:is="form"></component>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<div class="error-text mr-3 mb-3" v-if="!hasAllVariables">
				You must include the
				<span class="error-text-link" @click="showModal"
					>required variables</span
				>:
				<span class="pr-1" v-for="(value, name) in missingVars" :key="name">
					<span class="error-text-var">{{ '{' + name + '}' }}</span>
				</span>
			</div>
			<v-btn
				color="primary"
				class="mr-5 mb-3"
				@click="verify"
				:loading="showAccept"
				:disabled="!hasAllVariables"
				>Save</v-btn
			>
		</v-card-actions>
		<CustomizeFormRequiredModal ref="modal" />
	</v-card>
</template>

<script>
import CustomizeMixin from '@/views/customize/mixin/CustomizeMixin.js';
import CustomizeFormRequiredModal from '@/views/customize/components/CustomizeFormRequiredModal';
import {
	getTab,
	missingVariables,
} from '@/views/customize/utils/CustomizeUtil.js';

export default {
	mixins: [CustomizeMixin],
	components: { CustomizeFormRequiredModal },
	name: 'CustomizeSidebar',
	data: () => ({
		showAccept: false,
		tab: null,
		tabs: [],
		form: null,
	}),
	computed: {
		command() {
			return this.$store.getters.findCommand(this.$route.params.command);
		},
		customizeLoaded() {
			return this.$store.getters.customizeLoaded;
		},
		isMobile() {
			return this.$vuetify.breakpoint.width < 750;
		},
		missingVars() {
			return missingVariables(
				this.$route.params.command,
				this.$store.getters.selectedForm
			);
		},
		hasAllVariables() {
			return !Object.keys(this.missingVars).length;
		},
	},
	watch: {
		command() {
			this.tabs = getTab(this.command.link);
			this.selectTab(this.tabs[0]);
		},
		customizeLoaded() {
			this.tabs = getTab(this.command.link);
			this.selectTab(this.tabs[0]);
		},
	},
	methods: {
		showModal() {
			this.$refs.modal.show();
		},
		async verify() {
			if (!this.$refs.form.validate()) {
				return;
			}
			if (!this.hasAllVariables) {
				return;
			}
			await this.submit();
		},
		async submit() {
			this.showAccept = true;
			const opt = {
				text:
					"Any images and text that are inappropriate or breaks Discord's Terms of Service are not allowed. " +
					'We will check your your customized command. If it breaks these rules, you will be banned from using the bot.',
				width: 500,
				persistent: true,
				buttons: [
					{
						text: 'I Agree',
						color: 'primary',
						returns: true,
					},
					{
						text: 'Cancel',
						color: 'gray',
						outlined: true,
						returns: false,
					},
				],
			};
			const result = await this.$modal(opt).showInfo();
			if (!result) {
				this.showAccept = false;
				return;
			}
			await this.postForm();
		},
		async postForm() {
			try {
				await this.$store.dispatch('setCustomizedCommand', this.command.link);
				const opt = {
					text: 'Yay! Your command has been customized!',
					imgUrl: 'owo.png',
				};
				await this.$modal(opt).showInfo();
			} catch (err) {
				const opt = {
					text: 'Oh no! I failed to update your command... Please try again later.',
					imgUrl: 'owo-cry.png',
				};
				await this.$modal(opt).showError();
			}
			this.showAccept = false;
		},
		selectTab(tab) {
			if (!this.$store.getters.customizeLoaded) return;
			this.form = tab.component;
			const form = this.command.forms[tab.id];
			if (!form) {
				const opt = {
					text: 'Oh no! It seems like you have the wrong link!',
					imgUrl: 'owo-cry.png',
				};
				return this.$modal(opt).showError();
			}
			form.type = tab.id;
			this.$store.dispatch('setSelected', form);
		},
	},
	mounted() {
		this.tabs = getTab(this.command.link);
		this.selectTab(this.tabs[0]);
	},
};
</script>

<style scoped>
.editor {
	flex-grow: 1;
	max-height: 100%;
	overflow: auto;
}

.editor-mobile {
	flex-grow: 1;
}

.form {
	color: white;
}

.tab-text {
	color: white;
}

.help-button {
	position: absolute;
	right: 20px;
}

.error-text {
	color: var(--v-error-base);
}

.error-text-link {
	text-decoration: underline;
	cursor: pointer;
}

.error-text-var {
	font-family: Consolas, Andale Mono WT, Andale Mono, Lucida Console,
		Lucida Sans Typewriter, DejaVu Sans Mono, Bitstream Vera Sans Mono,
		Liberation Mono, Nimbus Mono L, Monaco, Courier New, Courier, monospace;
	padding: 2.73px;
	background-color: rgb(47, 49, 54);
}
</style>
